<template>
    <permission-item
        :permissions="permissions"
        :level="0"
        @permission-updated="permissionUpdated"
    />
</template>

<style>
</style>

<script>
import Store from '@/store/index'
import Permissions from '@/structures/Permission'
import PermissionItem from '@/components/Permissions/PermissionItem.vue'
import Permission from '@/structures/Permission'

export default {
    components: {
        PermissionItem,
    },
    props: {
        currentPermissions: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            permissions: {},
        }
    },
    computed: {
    },
    watch: {
        currentPermissions() {
            this.loadPermissions()
        },
    },
    created() {
        this.loadPermissions()
    },
    methods: {
        getUpdatedPermissions() {
            return this.permissions
        },
        loadPermissions() {
            const list = Store.state.permissions.permissionList
            this.permissions = Permissions.generateChildPermissions(list, this.currentPermissions)
        },
        permissionUpdated() {
            this.$emit('permission-updated')
        },
    },
}
</script>
