<template>
    <div>
        <b-card>
            <permissions-list
                ref="permission-list"
                :current-permissions="permissions"
                @permission-updated="savePermissions"
            />
        </b-card>
    </div>
</template>

<script>
import Axios from 'axios'
import PermissionsList from '@/components/Permissions/PermissionList.vue'
import Permission from '@/structures/Permission'
import {
    BCard,
} from 'bootstrap-vue'

export default {
    name: 'settings.users',
    components: {
        PermissionsList,
        BCard,
    },
    data() {
        return {
            loading: false,
            permissions: {},
        }
    },
    created() {
        this.loadPermissions()
    },
    methods: {
        loadPermissions() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}users/get-permissions`,
                data: { id: this.$route.params.id },
                method: 'POST',
            })
                .then(response => {
                    this.loading = false
                    this.permissions = response.data
                })
                .catch(() => {
                    this.loading = false
                })
        },
        savePermissions() {
            this.loading = true
            let permissions = this.$refs['permission-list'].getUpdatedPermissions()
            permissions = Permission.prepareForSaving(permissions)
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}users/save-permissions`,
                data: { id: this.$route.params.id, permissions },
                method: 'POST',
            })
                .then(() => {
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
    },
}
</script>
