<template>
    <div v-if="permissions !== {}">
        <template v-for="(permission, index) in permissions">
            <div :key="index">
                <div class="row">
                    <div class="permissions-item col" :style="isDark ? 'border: 1px solid #161d31;' : 'border: 1px solid #e1e1e1;'">
                        <div>
                            <div class="row">
                                <div class="col-4">
                                    <h6 class="permissions-item__title" :style="{paddingLeft: (paddingLeftPx * level) + 'px'}">
                                        {{ fixTitle(permission["name"]) }}
                                    </h6>
                                </div>
                                <div class="col-8">
                                    <div class="pull-right permissions-item__level-buttons">
                                        <template v-if="permission.max_level === 1">
                                            <div class="btn-group" role="group">
                                                <button type="button" :class="['btn btn-primary btn-sm', permission.currentLevel === 0 ? 'active' : '']" @click="updatePermission(permission, 0)">
                                                    No access
                                                </button>
                                                <button type="button" :class="['btn btn-primary btn-sm', permission.currentLevel === 1 ? 'active' : '']" @click="updatePermission(permission, 1)">
                                                    Has access
                                                </button>
                                            </div>

                                        </template>
                                        <template v-if="permission.max_level === 2">
                                            <div class="btn-group" role="group">
                                                <button type="button" :class="['btn btn-primary btn-sm', permission.currentLevel === 0 ? 'active' : '']" @click="updatePermission(permission, 0)">
                                                    No access
                                                </button>
                                                <button type="button" :class="['btn btn-primary btn-sm', permission.currentLevel === 1 ? 'active' : '']" @click="updatePermission(permission, 1)">
                                                    Read Only
                                                </button>
                                                <button type="button" :class="['btn btn-primary btn-sm', permission.currentLevel === 2 ? 'active' : '']" @click="updatePermission(permission, 2)">
                                                    Read / write
                                                </button>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <permission-item
                    :permissions="permission.children"
                    :level="level + 1"
                    @permission-updated="permissionUpdated"
                />
            </div>
        </template>
    </div>
</template>

<style>
    .permissions-item__title {
        text-transform: capitalize;
        padding: 0;
        margin: 0;
        line-height: 35px;
    }
    .permissions-item {
        border-radius: 5px;
        margin-bottom: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        height: 45px;
    }
    .permissions-item__level-buttons {

    }
</style>

<script>

import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
    name: 'permission-item',
    props: {
        permissions: {
            type: Object,
            default: () => {},
        },
        level: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            paddingLeftPx: 25,
        }
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
    methods: {
        fixTitle(title) {
            return title.replace(/_/g, ' ')
        },
        updatePermission(permission, level) {
            permission.currentLevel = level
            this.$emit('permission-updated')
        },
        permissionUpdated() {
            this.$emit('permission-updated')
        },
    },
}
</script>
